<div style="margin-left: 2%; margin-right: 2%">
    <app-notification-list></app-notification-list>
</div>

<div id="container">
    <h1 style="text-transform: none;">Welcome to Broker Business Portal, {{user}}!</h1>
</div>

<div class="labelclass">
    <label for="standard-select" style="color: black;">View Subscriber Type:</label>
    <div id="IndividualDropDown">
        <div class="select">
            <select id="standard-select" (change)="onChange($event)" [value] = selectedSubscriber>
                <option value="Group">Group</option>
                <option value="Individual">Individual</option>
            </select>
            <span class="focus"></span>
        </div>
    </div>
</div>

<app-group-list *ngIf="group"></app-group-list>
<app-individuallist *ngIf="!group"></app-individuallist>


