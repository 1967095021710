<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>

            <h6 id="MemberHeading">Members</h6>
            <div id="searchContainer" *ngIf="isGroup">
                <input [disabled]="!enableSearch" type="text" class="input-search" #filter (keyup)="applyFilter($event.target.value);" placeholder="Search by Name, DOB, Dependent, Member ID, Coverage Type, Coverage Start or Coverage End" #searchInput>
                
                <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" (click)="handleGtagClick( 'Members Table','Search Button')">Search</button>
            </div>
            
            <mat-table class="members-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

              <ng-container matColumnDef="memberId">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Member Id')">Member Id</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.memberId}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="accountId">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Account Id')">Account Id</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.accountId}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="name">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Name')">Name</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.name}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="dateOfBirth">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Date Of Birth')">Date Of Birth</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.dateOfBirth}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="coverageType">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Coverage Type')">Coverage Type</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.coverageType}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="coverageStart">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Coverage Start')">Coverage Start</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.coverageStart}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="coverageEnd">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Coverage End')">Coverage End</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.coverageEnd}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="status">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}">Status</mat-header-cell>


                <mat-cell [ngClass]="'margin-row'"
                          *matCellDef="let member">{{member.status}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="dependent">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'table-header margin-row'" (click)="toggleSortingOrder('Dependent')">Dependent</mat-header-cell>

                <mat-cell [ngClass]="'margin-row'"
                          *matCellDef="let member">{{member.dependent}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="planVersionEffectiveThruDt">

                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}">Plan Version Effective Thru Date</mat-header-cell>

                <mat-cell *matCellDef="let member">{{member.planVersionEffectiveThruDt}}</mat-cell>

              </ng-container>

              <ng-container matColumnDef="loading">
                <mat-footer-cell *matFooterCellDef colspan="7">
                  Loading data...
                </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef colspan="7">
                  No data.
                </mat-footer-cell>
              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

              <mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetails(row)" [ngClass]="{'selectedRow':row.memberId==selectedMemberId, 'termedMember': row.isTermedMember}" (click)="handleGtagClick( 'Members Table','Row Click - Step 1')"></mat-row>

              <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
              <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>


            </mat-table>
        
        
            <mat-paginator [pageSize]="10"
                           [pageSizeOptions]="[10]" [showFirstLastButtons]="true"
                           [hidePageSize]="true"
                           (click)="brokerClickPagination($event)"
                           ></mat-paginator>
        
        
        </div>
        
    </div>
    <div></div>
</div>


