
<div class="bookOfBusinessBackground">
  <h6 style="color: white;" id="MemberHeading">Book of Business</h6>
</div>

<div class="row section-title upper text-center light">
</div>
<div id="full-container">
  <div></div>
  <div class="grid-container" style="overflow: auto;">
      <div class="mainSearchBar">
        <div id="searchContainer">
          <div style="display: flex; height: 60px; padding-top: 8px ; width: 110%">
            <input [disabled]="!enableSearch" type="text" class="input-search" #filter (keyup)="updateSearchText($event.target.value);" placeholder="Type to filter" #searchInput>
            <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" (click)="handleGtagClick('Book of Business','Filter - Seach Button')">Search</button>
            <button class="exportButton" (click)="exportToCSV()">Export Group Data</button>
          </div>
        </div>

        <div class="filterSearchDiv">
          <label style="color: black;"for="standard-select">Broker ID </label>
          <div class="select">
            <select id="standard-select" (change)="updateBrokerIdFilter($event.target.value)" (click)="handleGtagClick('Book of Business','Filter - BrokerID Select')">>
              <option *ngFor="let item of getBrokerIds()" [value]="item">{{ item }}</option>
            </select>
            <span class="focus"></span>
          </div>
        </div>

        <div class="filterSearchDiv" style="left: 35%;">
          <label style="color: black; "for="standard-select">Renewal Month</label>
            <div class="select">
              <select id="standard-select" (change)="updateRenewalMonthFilter($event.target.value)" (click)="handleGtagClick('Book of Business','Filter - Renewal Month')">
                <option *ngFor="let item of getRenewalMonths()" [value]="item">{{ item }}</option>
              </select>
              <span class="focus"></span>
            </div>
          </div>
          
          <div class="filterSearchDiv" style="left: 70%;">
            <label style="color: black;" for="standard-select">Product Name</label>
            <div class="select">
              <select id="standard-select" (change)="updateProductNameFilter($event.target.value)" (click)="handleGtagClick('Book of Business','Filter - Product Name')">
                <option *ngFor="let item of getProductNames()" [value]="item">{{ item }}</option>
              </select>
              <span class="focus"></span>
            </div>
          </div>
      </div>


      <mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="groupName" matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="groupName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Group Name')"><b>Group Name</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{ toCamelCase(group.groupName) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="groupID">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Group Id')"><b>Group Id</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.groupID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Broker Name')"><b>Broker Name</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{ toCamelCase(group.brokerName) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerID">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Broker Id')"><b>Broker ID</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.brokerID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="renewalMonth">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Renewal Month')"><b>Renewal Month</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.renewalMonth}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="productName">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}" (click)="toggleSortingOrder('Product Name')"><b>Product Name</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.productName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.icon}}
            <i class="arrow right"></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GroupType">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{hide}" (click)="toggleSortingOrder('Group Type')"><b>Group Type</b></mat-header-cell>
          <mat-cell *matCellDef="let group">{{group.groupType}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
          <mat-footer-cell *matFooterCellDef colspan="7">
            Loading data...
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="noData">
          <mat-footer-cell *matFooterCellDef colspan="7">
            No data.
          </mat-footer-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="openGroup(row)" (click)="handleGtagClick('Book of Business','Row Click')"></mat-row>

        <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>


      </mat-table>


      <mat-paginator [pageSize]="10"
                     [pageSizeOptions]="[10]" [showFirstLastButtons]="true"
                     [hidePageSize]="true"
                     (click)="brokerClickPagination($event)"
                     ></mat-paginator>


    

  </div>
  <div></div>
</div>



